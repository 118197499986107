.titulo h2{text-transform: uppercase;font-weight: 700;margin-top: 0;font-size: 18px;}
.titulo img{width: 21px;}
.titulo .m{width: 30px;}
//estilos de prioridad
.flex-titulo{
  display: inline-flex;
  width: 100%;
  align-items: center;

}
.progresscircdetalle{
  margin-left: 45%;
  margin-top: 63%;
  width: 6vh;
}

.flex-titulo h2{width: 70%;}
.flex-titulo .important,.flex-titulo .media,.flex-titulo .baja{
  width: 30%;
  text-align: right;
}

.important span,.media span,.baja span{
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 50%;
}
.important span{
  background: rgb(211, 47, 47);
  box-shadow: 0px 0px 0px 2px rgba(211,47,47,0.5), 0px 0px 0px 4px rgba(211,47,47,0.3);
}
.media span{
  background: rgb(255,196,0);
  box-shadow: 0px 0px 0px 2px rgba(255,196,0,0.5), 0px 0px 0px 4px rgba(255,196,0,0.3);
}
.baja span{
  background: rgb(1,148,255);
  box-shadow: 0px 0px 0px 2px rgba(1,148,255,0.5), 0px 0px 0px 4px rgba(1,148,255,0.3);
}

.bg-1-important,.bg-2-important,.bg-3-important{
  width: 30%;
  text-align: right;
}

.bg-1-important span,.bg-2-important span,.bg-3-important span{
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 50%;
}

.bg-1-important span{
  background: rgb(1,148,255);
  box-shadow: 0px 0px 0px 2px rgba(1,148,255,0.5), 0px 0px 0px 4px rgba(1,148,255,0.3);
}
.bg-2-important span{
  background: rgb(255,196,0);
  box-shadow: 0px 0px 0px 2px rgba(255,196,0,0.5), 0px 0px 0px 4px rgba(255,196,0,0.3);
}

.bg-3-important span{
  background: rgb(211, 47, 47);
  box-shadow: 0px 0px 0px 2px rgba(211,47,47,0.5), 0px 0px 0px 4px rgba(211,47,47,0.3);
}

//estilos ficha manto
.item-ficha{
 width: 100%;
  display: inline-flex;

}
.item-ficha ul{padding-left: 0;list-style: none;margin:0;font-size: 14px;}
.cont-info-ficha{
  width: 100%;
  text-align: left;
}
.cont-info-ficha p{font-size: 15px;}
.titulo-vehiculo{
  font-size: 18px;
  margin-bottom: 10px;
}
.bg-xrail-ficha{
  background: url('../../images/xrail.png')no-repeat;
  background-size: contain;
  width: 75%;
  border-radius: 0 8px 8px 0;
  background-position: right;
}
.bg-atv-single-ficha{
  background: url('../../images/atv-single.png')no-repeat;
  background-size: contain;
  width: 75%;
  border-radius: 0 8px 8px 0;
  background-position: right;
}
.bg-atv-double-ficha{
  background: url('../../images/atv-double.png')no-repeat;
  background-size: contain;
  width: 75%;
  border-radius: 0 8px 8px 0;
  background-position: right;
}
.text-order{
  color: rgba(0,0,0,0.5);
}
.opacity{
  filter: opacity(0.3);
  position: absolute;
  right: 0;
  width: 100%;
  height: 100px;
  z-index: -1;
}