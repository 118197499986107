.tituloh2{text-transform: uppercase;font-weight: 700;margin-top: 0;font-size: 18px;}
.tituloimg{width: 21px;}
.titulo .m{width: 30px;}
.cont-carousel{display: inline-flex;}
.item-carosel{
  background: #fff;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.2);
  border-radius: 8px;
  display: inline-flex;
  width: 100%;
  margin-top: 1.5em;
}
.item-carosel ul{padding-left: 0;list-style: none;margin:0;padding: 14px 10px;font-size: 14px;}
.cont-info-item{
  width: 100%;
  text-align: left;
}
.cont-info-item li{
  padding: 2px 0;
}
.bg-xrail{
  background: url('../../images/xrail.png')no-repeat;
  background-size: contain;
  width: 75%;
  border-radius: 0 8px 8px 0;
  background-position: right;
}
.bg-atv-single{
  background: url('../../images/atv-single.png')no-repeat;
  background-size: contain;
  width: 75%;
  border-radius: 0 8px 8px 0;
  background-position: right;
}
.bg-atv-double{
  background: url('../../images/atv-double.png')no-repeat;
  background-size: contain;
  width: 75%;
  border-radius: 0 8px 8px 0;
  background-position: right;
}
.titulo-vehiculo{
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.logo-header{
  width: 110px;
}

.titulo h2{text-transform: uppercase;font-weight: 700;margin-top: 0;font-size: 18px;}
.titulo img{width: 21px;}
.titulo .m{width: 30px;}
.scroll{overflow: scroll;height: 150px;align-content: center;display: flex;    align-items: center;}
.ver-todo,.ver-menos{width: 100%;text-align: right;font-size: 14px;transition: 0.4s;}
.ver-menos ion-icon{
  transition: 0.4s;
  transform: rotate(-180deg);
}
.ver-menos .scroll{
  transition: 0.4s;
  display: inline-flex;
  height: auto;
  overflow: visible;
}
.ver-menos .scroll .cont-carousel{display: inline-block; transition: 0.4s;}
.ver-menos .scroll .cont-carousel .item-carosel{width: 100%;    margin-bottom: 15px;transition: 0.4s;}
.cont-carousel{display: inline-flex;}
.item-carosel ul{padding-left: 0;list-style: none;margin:0;padding: 14px 10px;font-size: 14px;}
.cont-info-ficha{
  width: 100%;
  text-align: left;
}

.titulo-vehiculo-ficha{
  font-size: 18px;
  margin-bottom: 10px;
}

.concluidoCalendar span{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background: rgb(8, 149, 8);
  border-radius: 50%;
  box-shadow: 0px 0px 0px 2px rgba(8,149,8,0.5), 0px 0px 0px 4px rgba(8,149,8,0.3);
}
.concluidoCalendar span img{width: 10px;}

.concluidoCalendar{
  margin-right:23px;
  /*width: 100%;*/
}