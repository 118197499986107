
.bg-blanco{
    background: #fff;
    border-radius: 8px;
    margin-bottom: 2em !important;
    margin: 0;
}
.btn-login{
    padding: 0 8px;
    margin-top: 3em;
}
.ion-text-center, [text-center] {
    text-align: center !important;
}
.button-native{
    margin-left: 0;
    margin-right: 0;
    display: block;
    width: 100%;
    clear: both;
    contain: content;
    height: 8%;
    border:none;
    border-radius: 0.5em;
    background: #D32F2F !important;
    color: #ffffff;
}
.bg-img-login{
    background:url('../../images/bg-login.jpg');
    position: fixed;
    background-size: cover;
    width: 100vw;
    left: 0;
    top: 0;
}
.bg-img-login .page__background{
    background:url('../../images/bg-login.jpg');
    position: fixed;
    background-size: cover;
    width: 100vw;
    left: 0;
    top: 0;
    transition:0.4s;
}
.fondo-login{
    height: 100%;
}
.fondo-login .cont-title h2{
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
    color: #fff;
    margin-top: 8px;
    margin-bottom: 2em;
}
.fondo-login .cont-title{
    position: absolute;
    left: 0;
    right: 0;
    top: -5em;
    bottom: 0;
    margin: auto;
    display: inline-table;
    width: 100%;
}
.btn-iniciar{
    position: absolute;
    font-size: 16px;
    border: none;
    bottom: 0px;
    background: rgba(255,255,255,9);
    width: 100%;
    padding: 15px 0;
    text-align: center;
    color: #f5313e;
    font-weight: 600;
}
.btn-iniciar:hover{
    position: absolute;
    font-size: 16px;
    border: none;
    bottom: 0px;
    background: rgba(255,255,255,9);
    width: 100%;
    padding: 15px 0;
    text-align: center;
    color: #f5313e;
    font-weight: 600;
}
.cont-title .img-login{
    width: 250px;
}

.fondo-login .cont-login {
    position: absolute;
    left: 0;
    right: 0;
    height: 0;
    /* top: 200vh; */
    /* bottom: 0; */
    overflow: hidden;
    margin: auto;
    width: 300px;
}


.fondo-login-activo .cont-login{
    position: absolute;
    left: 0;
    right: 0;
    height: 80vh;
    top: 200vh;
    bottom: 0;
    margin: auto;
    width: 300px;
}


   .fondo-login-activo .page__background{
       height: 42vh;
       transition: 1.5s;
   }
.fondo-login-activo .cont-title{
    display: none;
}
.fondo-login-activo .cont-login{
    position: absolute;
    left: 0;
    right: 0;
    transition: 0.6s;
    height: 80vh;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 300px;
}
.fondo-login-activo .btn-iniciar{
    display: none;
}
.img-login{
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 165px;
    transition: 0.6s;
}
.cont-login h2{
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  margin-top: 8px;
  margin-bottom: 2em;
}
.text-checkbox{
    font-size: 13px;
    margin-left: 5px;
}
.check-group{display: inline-flex;}
.size-13{font-size: 13px;}

//ESTILOS INPUTS
.cont-input-L{border: 1px solid rgba(0,0,0,0.2);
    border-radius: 5px;margin-bottom: 20px;}
.cont-input-L input{
    background-image: none !important;
}
.cont-input-L i{
    color: rgba(0,0,0,0.3);
}
.cont-input-L ons-input{
    width: 90%;
}

///modal
#modal {
    height: 100%;
  }
  .modalHistoria{
    background: #fff;
    color: #333;
    padding: 15px;
    width: 80vw;
    margin-right: auto;
    margin-left: auto;
    border-radius: 8px;
  }
  .Modal-content-historia{
    background: white;
    width: 85vw;
    border-radius: 8px;
    padding: 15px;
    height: 80vh;
    text-align: left;
    overflow: auto;
    //min-height: 40vh;
  }
  .modal-content-historia .fail{
    font-size: 35px;
    color: #f70606;
    animation: bounceIn 0.8s;
  }
  .tituloModal{
    display: inline-flex;
    margin-bottom: 1em;
    width: 100%;
  }
  .Modal-content h2{
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    color: rgba(0,0,0,0.6);
  }
  @-webkit-keyframes bounceIn{0%,20%,40%,60%,80%,to{-webkit-animation-timing-function:cubic-bezier(.215,.61,.355,1);animation-timing-function:cubic-bezier(.215,.61,.355,1)}0%{opacity:0;-webkit-transform:scale3d(.3,.3,.3);transform:scale3d(.3,.3,.3)}20%{-webkit-transform:scale3d(1.1,1.1,1.1);transform:scale3d(1.1,1.1,1.1)}40%{-webkit-transform:scale3d(.9,.9,.9);transform:scale3d(.9,.9,.9)}60%{opacity:1;-webkit-transform:scale3d(1.03,1.03,1.03);transform:scale3d(1.03,1.03,1.03)}80%{-webkit-transform:scale3d(.97,.97,.97);transform:scale3d(.97,.97,.97)}to{opacity:1;-webkit-transform:scaleX(1);transform:scaleX(1)}}@keyframes bounceIn{0%,20%,40%,60%,80%,to{-webkit-animation-timing-function:cubic-bezier(.215,.61,.355,1);animation-timing-function:cubic-bezier(.215,.61,.355,1)}0%{opacity:0;-webkit-transform:scale3d(.3,.3,.3);transform:scale3d(.3,.3,.3)}20%{-webkit-transform:scale3d(1.1,1.1,1.1);transform:scale3d(1.1,1.1,1.1)}40%{-webkit-transform:scale3d(.9,.9,.9);transform:scale3d(.9,.9,.9)}60%{opacity:1;-webkit-transform:scale3d(1.03,1.03,1.03);transform:scale3d(1.03,1.03,1.03)}80%{-webkit-transform:scale3d(.97,.97,.97);transform:scale3d(.97,.97,.97)}to{opacity:1;-webkit-transform:scaleX(1);transform:scaleX(1)}}.bounceIn{-webkit-animation-duration:.75s;animation-duration:.75s;-webkit-animation-name:bounceIn;animation-name:bounceIn}
  .modal {
    width: 100vw;
    //position: fixed;
    transition: 1.1s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    &.off {
      opacity: 0;
      visibility: hidden;
      filter: blur(8px);
      transform: scale(0.33);
      box-shadow: 1rem 0 0 rgba(black, 0.2);
    }
    .icon-plus{
      background: rgba(114,124,142,0.2);
      border-radius: 50%;
      font-size: 16px;
      width: 25px;
      color: #727C8E;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 25px;
      margin-right: 5px;
    }
    .content {
      padding: 1rem;
    }
  }
  
  .btn-aceptar-fail{
    padding: 10px 10px;
    background: #e00c0c;
    color: #fff;
    border-radius: 5px;
    border:none;
  }
  .btn-aceptar-fail i{
    margin-right: 0px;
  }
  .Circular-login{
    margin-top: 55%;
    width: 6vh;
    margin-left: 44%;
    margin-bottom: 15%;
  }