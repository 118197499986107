.progresscircdetalle{
  margin-left: 45%;
  margin-top: 63%;
  width: 6vh;
}
.btn-aceptar{
  padding: 10px 10px;
  background: #008CF2;
  color: #fff;
  border-radius: 5px;
  border:none;
}