
.modal-content-notification{
  //background: white;
  //width: 85vw;
  //position: absolute;
  //border-radius: 8px;
  //padding: 15px;
  //right: 0;
  //left: 0;
  //top: 0;
  //bottom: 0;
  //margin: auto;
  //height: 475px;
  //overflow: auto;
  background: white;
  width: 85vw;
  border-radius: 8px;
  padding: 15px;
  min-height: 27vh;
  max-height: auto;
  text-align: left;
  overflow: auto;
  //min-height: 40vh;
}
.titulo-modal-notification{
  display: inline-flex;
  margin-bottom: 1em;
  width: 100%;
}
.modal-content-notification h2{
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: rgba(0,0,0,0.6);
}

.fch-inicio,.fch-fin{width: 100%;}
.fch-fin{
  margin-left: 10px;
}
.fch-fin label{
  text-align: left;
}
.fch-inicio input,.fch-fin input{
  width: 135px;
}
.fch-inicio .rdatez svg,.fch-fin .rdatez svg{
  fill: rgba(114,124,142,0.8);
}