
.imgmanto{
  width: 30px;
}
.tituloimg{
  width: 30px;
}
.tituloimg .m{
  display: none;
}
.cont-add{font-size: 15px;display: inline-flex;font-weight: 500;margin-bottom:10px;width: 100%;}
.txt-add{width: 80%;display: inline-flex;align-items: center;}
.cont-add span{
  background: rgba(114,124,142,0.2);
  border-radius: 50%;
  font-size: 18px;
  width: 25px;
  color: #727C8E;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  margin-right:5px;
}
.Circular-form{
  margin-left: 42%;
  margin-top: 42%;
  margin-bottom: 35%;
  width: 6vh;
}

.filtro{text-align: right;width: 20%;font-size: 18px;}
.cont-full{display: inline-flex;
  align-items: center;width: 100%;margin-bottom:15px;}
.expand{
  width: 30%;
  text-align: right;
  float: right;
  display: inline-block;
  position: relative;
  padding-right: 15px;
}
.fch-iten-manto{      padding: 0 15px;
  width: 70%;
  color: rgba(0,0,0,0.6);
  font-size: 15px;
}
.page__content{overflow-x: hidden;}
.iten-manto{
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 0 0 0 8px;
  border-radius: 75px 0 0 75px;
  font-size: 14px;
  margin-left: 5px;
  margin-bottom: 15px;
}
.cont-flex{justify-content: center;
  align-items: center;
  width: 100%;
  display: inline-flex;}
.icon-plus{
  background: rgba(114,124,142,0.2);
  border-radius: 50%;
  font-size: 16px;
  width: 25px;
  color: #727C8E;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  margin-right: 5px;
}
.txt-small{font-size: 12px;color: rgba(0,0,0,0.5);}
.important span,.media span,.baja span,.finalizado span{
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 50% !important;
}
.important span{
  background: rgb(211, 47, 47);
  box-shadow: 0px 0px 0px 2px rgba(211,47,47,0.5), 0px 0px 0px 4px rgba(211,47,47,0.3);
}
.media span{
  background: rgb(255,196,0);
  box-shadow: 0px 0px 0px 2px rgba(255,196,0,0.5), 0px 0px 0px 4px rgba(255,196,0,0.3);
}
.baja span{
  background: rgb(1,148,255);
  box-shadow: 0px 0px 0px 2px rgba(1,148,255,0.5), 0px 0px 0px 4px rgba(1,148,255,0.3);
}

.concluido span{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background: rgb(8, 149, 8);
  border-radius: 50%;
  box-shadow: 0px 0px 0px 2px rgba(8,149,8,0.5), 0px 0px 0px 4px rgba(8,149,8,0.3);
}

//estilos para el historial de los mantenimientos
.storycom1 span,.storycom2 span,.storycom3 span,.storycom5 span{
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 50% !important;
}
.storycom3 span{
  background: rgb(211, 47, 47);
  box-shadow: 0px 0px 0px 2px rgba(211,47,47,0.5), 0px 0px 0px 4px rgba(211,47,47,0.3);
}
.storycom2 span{
  background: rgb(255,196,0);
  box-shadow: 0px 0px 0px 2px rgba(255,196,0,0.5), 0px 0px 0px 4px rgba(255,196,0,0.3);
}
.storycom1 span{
  background: rgb(1,148,255);
  box-shadow: 0px 0px 0px 2px rgba(1,148,255,0.5), 0px 0px 0px 4px rgba(1,148,255,0.3);
}
.spanModoalSucces{
  display: block;
}
.Circular-MantoHistoria{
  margin-left: 44%;
  margin-top: 55%;
}
.storycom5 span{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background: rgb(8, 149, 8);
  border-radius: 50%;
  box-shadow: 0px 0px 0px 2px rgba(8,149,8,0.5), 0px 0px 0px 4px rgba(8,149,8,0.3);
}
/////////////////////////////////////////////////
.concluido span img{width: 10px;}
.cont-txt-manto{
  padding: 18px;
  width: 100%;
}
.concluido{
  margin-right:23px;
  /*width: 100%;*/
}