.list-notificaciones{

}
.Circular-notif{
	margin-top: 15%;
}
.list-header {
	margin: 0;
	list-style: none;
	text-align: left;
	display: block;
	box-sizing: border-box;
	padding: 0 0 0 15px;
	font-size: 12px;
	font-weight: 500;
	color: #1f1f21;
	min-height: 24px;
	line-height: 25px;
	text-transform: uppercase;
	position: relative;
	 background-color: transparent;
	background-size: 100% 1px;
	background-repeat: no-repeat;
	background-position: top;
	/* background-image: linear-gradient(0deg, #ccc, #ccc 100%); */
}
.list{background-size: 100% 1px, 100% 0px !important;}
.tituloimg{width: 21px;}
.logo-header{
	width: 110px;
}
.icon-exit{
	font-size: 25px;
}
.titulo h2{text-transform: uppercase;font-weight: 700;margin-top: 1em;font-size: 18px;color: rgba(0,0,0,0.4);}
.bg-avatar{
	background: rgb(211, 47, 47);
	display: flex;
    justify-content: center;
    align-items: center;
	width: 45px;height: 45px;border-radius: 50%;
	margin-right: 5px;
}
.bg-avatar img{width: 25px;height: 21px;border-radius: 0;}
.list-notificaciones p{white-space:normal;}
.info-notificacion{text-align: right;padding-left:5px;}
.hora{
	width: 48px;
	font-size: 11px;
    color: rgba(0,0,0,0.4);
    margin-bottom: 15px;
}
.no-leido,.leido{
	width: 10px;
	height: 10px;
	display: inline-block;
	border-radius: 50%;
	background: rgb(1,148,255);
	float: right;
}
.leido{
	background: rgba(0,0,0,0.3);
}