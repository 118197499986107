.Bg-toolbar{
  background-color: #000000;
}
.logo-header{
  width: 110px;
  margin-top: 5%;
  margin-left: 17%;
}
.icon-exit{
  font-size: 20px;
  color: white;
}
.back-button__icon{
  fill:white;
}