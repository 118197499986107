.cont-categoria{
  list-style: none;
  padding-left: 0;
  text-align: left;
  padding: 0 15px;
}
.Home-circular{
  width: 6vh;
  margin-left: 45%;
  margin-top: 53%;
}
:checked + .tabbar__button {
  background: rgba(211, 47, 47,0.125);
  //background-color: transparent;
  box-shadow: none;
  border-top: none;
  color: #31313a !important;
}

.cont-categoria li{
  padding: 15px 0;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.cont-categoria .final{
  border: none;
}
.tabbar--material__border{
  height: 0;
}
.txt-categoria{
  margin-left: 20px;
  width: 100%;
}
.finalizado span{
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 50% !important;
}
.imgmanto{
  width: 30px;
}
.tituloimg{
  width: 30px;
}
.tituloimg .m{
  display: none;
}
.cont-add{font-size: 15px;display: inline-flex;font-weight: 500;margin-bottom:10px;width: 100%;}
.txt-add{width: 80%;display: inline-flex;align-items: center;}
.cont-add span{
  background: rgba(114,124,142,0.2);
  border-radius: 50%;
  font-size: 18px;
  width: 25px;
  color: #727C8E;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  margin-right:5px;
}
.filtro{text-align: right;width: 20%;font-size: 18px;}
.cont-full{display: inline-flex;
  align-items: center;width: 100%;margin-bottom:15px;}
.expand{
  width: 30%;
  text-align: right;
  float: right;
  display: inline-block;
  position: relative;
  padding-right: 15px;
}
.fch-iten-manto{      padding: 0 15px;
  width: 70%;
  color: rgba(0,0,0,0.6);
  font-size: 15px;
}

.cont-flex{justify-content: center;
  align-items: center;
  width: 100%;
  display: inline-flex;}
.icon-plus{
  background: rgba(114,124,142,0.2);
  border-radius: 50%;
  font-size: 16px;
  width: 25px;
  color: #727C8E;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  margin-right: 5px;
}
.txt-small{font-size: 12px;color: rgba(0,0,0,0.5);}
.important span,.media span,.baja span,.finalizado span{
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 50% !important;
}
.important span{
  background: rgb(211, 47, 47);
  box-shadow: 0px 0px 0px 2px rgba(211,47,47,0.5), 0px 0px 0px 4px rgba(211,47,47,0.3);
}
.media span{
  background: rgb(255,196,0);
  box-shadow: 0px 0px 0px 2px rgba(255,196,0,0.5), 0px 0px 0px 4px rgba(255,196,0,0.3);
}
.baja span{
  background: rgb(1,148,255);
  box-shadow: 0px 0px 0px 2px rgba(1,148,255,0.5), 0px 0px 0px 4px rgba(1,148,255,0.3);
}

.concluido span{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background: rgb(8, 149, 8);
  border-radius: 50%;
  box-shadow: 0px 0px 0px 2px rgba(8,149,8,0.5), 0px 0px 0px 4px rgba(8,149,8,0.3);
}
.concluido span img{width: 10px;}
.cont-txt-manto{
  padding: 18px;
}
.concluido{
  margin-right:23px;
  /*width: 100%;*/
}

.top_4 h2 i{
  color: #D32F2F;
  margin-right: 5px;
}