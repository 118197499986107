@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
//.fc-unthemed{
//
//}
.top_4{
  margin-top: 1em;
}
.top_4 h2{
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 0;
  font-size: 18px
}
.fc-toolbar h2{
  font-size: 18px;
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
}
.fc-day-number{
  text-align: center;
  width: 100%;
}
.fc-today-button{
  display: none;
}
.fc th, .fc td{
  vertical-align: middle;
}
.fc-unthemed td .fc-widget-content {
  background: transparent !important;
}
//.fc-today{
//  background: #D32F2F !important;
//  width: 40px;
//  height: 40px;
//  color: #fff;
//  border-radius: 8px;
//}
.fc-week {
  min-height: 2em !important;
  font-size: 14px;
}
.fc-day,.fc-day-header,.fc-widget-content,.fc-widget-header{
  border-width: 0 !important;
}
.fc-widget-header{
  padding-bottom: 10px;
}
.Callendar-circular{
  width: 6vh;
  margin-left: 44%;
  margin-top: 21%;
}
.parrafotexthome{
  text-align: center;
}
.fc-button-primary,.fc-button-primary,.fc-button-primary:hover,.fc-button-primary:active,.fc-button-primary:focus,.fc-button-primary:not(:disabled):active, .fc-button-primary:not(:disabled).fc-button-active{
  background: transparent;
  border-color: transparent;
  color: #D32F2F;
  border:none !important;
}
.fc-event-container{
  height: 25px;
  width: 45px;
  top: 0;
  position: absolute;
}
.fc-dayGrid-view .fc-week-number, .fc-dayGrid-view .fc-day-number{
  padding: 2px;
  padding-top: 5px;
}
.fc th, .fc td{
  border-style: solid;
  border-width: 1px;
  padding: 0;
  margin-top: 5px;
}
.fc-button-primary .fc-icon{
  font-weight: 600;
}
.calendarhome .fc-bgevent{
  background:none !important;
}
.class-inicio{
  width: 8px;
  height: 10px;
  background-color:#089508 !important;
  border-radius: 50%;
  display: block;
  position: absolute;
  border:1px solid #089508;
  top: -5px;
  margin-left: 30px;
}
.class-fin{
  width: 8px;
  height: 10px;
  background-color:#D32F2F;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -5px;
  margin-left: 30px;
  border:1px solid #D32F2F;
}
.inline-flex{display: inline-flex;width: 100%;}
.inline-flex h2{width: 100%;}
.cont-categoria li i {
  color: rgba(0,0,0,0.4);
}

.inicio{
  width: 10px;
  height: 10px;
  background-color: #089508;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #089508;
}

.fin{
  width: 10px;
  height: 10px;
  background-color: #D32F2F;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #D32F2F;
}