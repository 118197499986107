.fc-bgevent{
  background: #d32f2f;
  opacity: 0.7;
}

div .fc-bgevent-skeleton:nth-child(0){
  background: blue;
}
.progresscircdetalle{
  margin-left: 45%;
  margin-top: 63%;
  width: 6vh;
}