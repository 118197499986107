.opacity{
  position: absolute;
  right: 0;
  width: 100%;
  height: 100px;
  z-index: -1;
}
.cont-filtro{margin-bottom: 15px;}
.filtro{float: right}
.cont-titulo{margin: 18px 0;}
.cont-titulo i{float: right;}
.cont-categoria{
  list-style: none;
  padding-left: 0;
  text-align: left;
  padding: 0 15px;
}
.cont-categoria li{
  padding: 15px 0;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.cont-categoria .final{
  border: none;
}
.txt-categoria{
  margin-left: 20px;
  width: 100%;
}
.finalizado span{
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 50% !important;
}
.finalizado span{
  background: rgb(8, 149, 8);
  box-shadow: 0px 0px 0px 2px rgba(8,149,8,0.5), 0px 0px 0px 4px rgba(8,149,8,0.3);
}

#modal {
  height: 100%;
}
.modalHistoria{
  background: #fff;
  color: #333;
  padding: 15px;
  width: 80vw;
  margin-right: auto;
  margin-left: auto;
  border-radius: 8px;
}
.Modal-content-historia{
  background: white;
  width: 85vw;
  border-radius: 8px;
  padding: 15px;
  height: 80vh;
  text-align: left;
  overflow: auto;
  //min-height: 40vh;
}
.Modal-content{
  background: white;
  width: 85vw;
  border-radius: 8px;
  padding: 15px;
  height: 80vh;
  text-align: left;
  overflow: auto;
  //min-height: 40vh;
}
.modal-content-historia .check{
  font-size: 35px;
  color: #09b709;
  animation: bounceIn 0.8s;
}
.tituloModal{
  display: inline-flex;
  margin-bottom: 1em;
  width: 100%;
}
.Modal-content h2{
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: rgba(0,0,0,0.6);
}
@-webkit-keyframes bounceIn{0%,20%,40%,60%,80%,to{-webkit-animation-timing-function:cubic-bezier(.215,.61,.355,1);animation-timing-function:cubic-bezier(.215,.61,.355,1)}0%{opacity:0;-webkit-transform:scale3d(.3,.3,.3);transform:scale3d(.3,.3,.3)}20%{-webkit-transform:scale3d(1.1,1.1,1.1);transform:scale3d(1.1,1.1,1.1)}40%{-webkit-transform:scale3d(.9,.9,.9);transform:scale3d(.9,.9,.9)}60%{opacity:1;-webkit-transform:scale3d(1.03,1.03,1.03);transform:scale3d(1.03,1.03,1.03)}80%{-webkit-transform:scale3d(.97,.97,.97);transform:scale3d(.97,.97,.97)}to{opacity:1;-webkit-transform:scaleX(1);transform:scaleX(1)}}@keyframes bounceIn{0%,20%,40%,60%,80%,to{-webkit-animation-timing-function:cubic-bezier(.215,.61,.355,1);animation-timing-function:cubic-bezier(.215,.61,.355,1)}0%{opacity:0;-webkit-transform:scale3d(.3,.3,.3);transform:scale3d(.3,.3,.3)}20%{-webkit-transform:scale3d(1.1,1.1,1.1);transform:scale3d(1.1,1.1,1.1)}40%{-webkit-transform:scale3d(.9,.9,.9);transform:scale3d(.9,.9,.9)}60%{opacity:1;-webkit-transform:scale3d(1.03,1.03,1.03);transform:scale3d(1.03,1.03,1.03)}80%{-webkit-transform:scale3d(.97,.97,.97);transform:scale3d(.97,.97,.97)}to{opacity:1;-webkit-transform:scaleX(1);transform:scaleX(1)}}.bounceIn{-webkit-animation-duration:.75s;animation-duration:.75s;-webkit-animation-name:bounceIn;animation-name:bounceIn}
.modal {
  width: 100vw;
  //position: fixed;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  &.off {
    opacity: 0;
    visibility: hidden;
    filter: blur(8px);
    transform: scale(0.33);
    box-shadow: 1rem 0 0 rgba(black, 0.2);
  }
  .icon-plus{
    background: rgba(114,124,142,0.2);
    border-radius: 50%;
    font-size: 16px;
    width: 25px;
    color: #727C8E;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    margin-right: 5px;
  }
  .content {
    padding: 1rem;
  }
}

//ESTILOS INPUTS
.cont-form{

}
.cont-input{
  margin-bottom: 15px;
}
.cont-input label{
  display: block;
  margin-bottom: 5px;
  color: rgba(114,124,142,0.9);
}
.cont-input select,.cont-input input ,.cont-input textarea,.fch-inicio input, .fch-fin input{

  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  width: 100%;
  padding: 10px 0px;
  font-size: 17px;
  border: none;
  background: rgba(114,124,142,0.1);
  color: rgba(114,124,142,0.8);
}
.fch-inicio,.fch-fin{width: 100%;}
.fch-fin{
  margin-left: 0px;
}
.spanerror{
  color: red;
  font-size: 13px;
  font-weight: 100;
  position: relative;
}
.SpanFilter{
  margin-bottom: 5%;
  display: block;
}
.fch-fin label{
  text-align: left;
}
.fch-inicio input,.fch-fin input{
  width: 100%;
}
.fch-inicio .rdatez svg,.fch-fin .rdatez svg{
  fill: rgba(114,124,142,0.8);
}
//BUTTONS
.actions{text-align: right}
.btn-cancelar{color: rgba(114,124,142,1) !important;background: none !important;margin-left: 5px;}
.btn-cancelar, .btn-aceptar{
  padding: 10px 10px;
  background: #008CF2;
  color: #fff;
  border-radius: 5px;
  border:none;
  width: 50%;
}
.btn-aceptar i{
  margin-right: 0px;
}
.classParraf{
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncate {

}
.rdatez{
  width: 100%;
}
  //ESTILOS CALENDARIO
.rdatez-picker{padding: 0 !important;}
.rdatez-picker>div{
  padding: 15px !important;
}
.rdatez-day:nth-last-child(-n+8){
  border-bottom: 0 !important;
}
.rdatez-calendar {
  margin-top: 70px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}
.rdatez-calendar span{
  text-transform: capitalize;
}
.rdatez-daysofweek>span{font-size: 16px !important;font-weight: 600;color: #000000}
.rdatez-calendar a,.disallow-future a.rdatez-day, .disallow-past a.rdatez-day{font-size: 16px;color: #000000;font-weight: 500;}
.rdatez-calendar-title{
  padding-top: 0 !important;
  color: #000 !important;
}
.rdatez-day,.rdatez-day-spacer{border-right: 0 !important;border-bottom:0 !important;border: none !important;}
.disallow-future a.rdatez-day.today.selected-day, .disallow-past a.rdatez-day.selected-day, .disallow-past a.rdatez-day.today.selected-day, .highlight-weekends a.rdatez-day.weekday-0.selected-day, .highlight-weekends a.rdatez-day.weekday-6.selected-day, .rdatez-day.past-day.selected-day, a.selected-day {
  background: #D32F2F !important;
  width: 40px;
  height: 40px;
  color: #fff;
  border-radius: 8px;
}


.bg-import1 span, .bg-import2 span, .bg-import3 span, .bg-import5 span {
   width: 14px;
   height: 14px;
   display: inline-block;
   border-radius: 50% !important;
 }
.bg-state1 span, .bg-state2 span {
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 50% !important;
}

.bg-state1 span{
  background: #969696;
  box-shadow: 0px 0px 0px 2px rgba(125, 125, 125, 0.5), 0px 0px 0px 4px rgba(122, 122, 122, 0.3);
  border: none;
  //background: #089508;
  //box-shadow: 0px 0px 0px 2px rgba(8, 149, 8, 0.5), 0px 0px 0px 4px rgba(8, 149, 8, 0.3);
  //border: none;
}

.bg-state2 span{
  background: #089508;
  box-shadow: 0px 0px 0px 2px rgba(8, 149, 8, 0.5), 0px 0px 0px 4px rgba(8, 149, 8, 0.3);
  border: none;

}
.cont-categoria .bg-importli5 {
  border: none;
}
.bg-import1 span{
  background: #0194ff;
  box-shadow: 0px 0px 0px 2px rgba(1, 148, 255, 0.5), 0px 0px 0px 4px rgba(1, 148, 255, 0.3)
}
.bg-import2 span{
  background: #ffc400;
  box-shadow: 0px 0px 0px 2px rgba(255, 196, 0, 0.5), 0px 0px 0px 4px rgba(255, 196, 0, 0.3)
}
.bg-import3 span{
  background: #d32f2f;
  box-shadow: 0px 0px 0px 2px rgba(211, 47, 47, 0.5), 0px 0px 0px 4px rgba(211, 47, 47, 0.3);

}
.bg-importli3{
  border: none;
}
.bg-stateli2{
  border:none !important;
}
.bg-import5 span{
  background: #089508;
  box-shadow: 0px 0px 0px 2px rgba(8, 149, 8, 0.5), 0px 0px 0px 4px rgba(8, 149, 8, 0.3);
  border: none;
}
.toast__message {
  font-size: 14px;
  color: red;
  flex-grow: 1;
  text-align: left;
  margin: 0 16px 0 0;
  white-space: normal;
}
.toast--material{
  left: 0;
  right: 0;
  bottom: 0;
z-index: 100000;
  margin: 0;
  background-color: rgba(255, 255, 255, .8);
  border-radius: 0;
  padding: 16px 24px;
}

.Circular-Notif{
  width: 6vh;
  margin-top: 60%;
  margin-left: 43%;
}
.lafilter{
  text-align: center !important;

}
.lifilter2{
  border: none !important;
}
.lifilter3{
  text-align: center;
}